<template>
  <section class="q-pa-md">
    <h1>Dashboard</h1>
    <p>Welcome back!</p>
    <q-btn
      :to="`/${$route.params.locale}/dashboard/group`"
      color="primary"
      label="Groups"
    />
  </section>
</template>

<script>
  export default {
    meta: {
      title: 'Dashboard',
    },
  }
</script>
